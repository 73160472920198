import React from "react";
import { GradientPinkBlue as IconGradient } from "@vx/gradient";

export function IntegrationSVG() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 1000 1000"
      fill="url(#iconGradient)"
    >
      <IconGradient id="iconGradient" />
      <g>
        <g>
          <g>
            <path d="M482.6,542.3L266.7,432.8c-4.9-2.5-10.4-2.3-15-0.2v0L21,542.2c-6.4,2.5-11,8.8-11,16.2v299h0.1c0,6.1,3.2,12.1,8.9,15.2L242.3,995l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0.1l0.2,0.1l0.1,0l0.1,0l0.1,0.1l0.3,0.1l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0l0.2,0.1l0.1,0l0.1,0l0.2,0l0.1,0.1l0,0l0.1,0l0.2,0l0.1,0v0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0.1l0.2,0h0.1l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0h0.1l0.1,0h0.2l0.1,0h0l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0.1h0.1h0.3h0.4h0.1h0.1l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0l0.1,0h0.2l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0h0.1l0.2,0l0.2-0.1l0,0l0.1,0l0.2,0l0.1,0l0,0l0.1,0l0.2,0l0.1,0v0l0.2-0.1l0.2,0l0.1,0l0.1,0l0.2-0.1l0.1,0l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.3-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.1-0.1l0.1,0l0.1,0l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0l0.1-0.1l223.3-122.4c5.7-3.1,8.9-9.1,8.9-15.2h0.1v-299C493.5,551.1,489,544.8,482.6,542.3z M258.8,467.5l48.6,24.6l-187.2,97.6l-55.1-30.2L258.8,467.5z M234.6,951.4L44.4,847.1V587.5l58,31.8v57.4l43,20.3v-54.2l89.2,48.9L234.6,951.4L234.6,951.4z M251.7,661.8l-86-47.1l189-98.6l84.5,42.9L251.7,661.8z" />
            <path d="M979.1,542.3L763.2,432.8c-4.9-2.5-10.4-2.3-15-0.2v0L517.4,542.2c-6.4,2.5-11,8.8-11,16.2v299h0.1c0,6.1,3.2,12.1,8.9,15.2L738.8,995l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0.1l0.2,0.1l0.1,0l0.1,0l0.1,0.1l0.3,0.1l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0l0.2,0.1l0.1,0l0.1,0l0.2,0l0.1,0.1l0,0l0.1,0l0.2,0l0.1,0v0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0.1l0.2,0h0.1l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0h0.1l0.1,0h0.2l0.1,0h0l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0.1h0.1h0.3h0.4h0.1h0.1l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0l0.1,0h0.2l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0h0l0.2,0l0.2-0.1l0,0l0.1,0l0.2,0l0.1,0l0,0l0.1,0l0.2,0l0.1,0v0l0.2-0.1l0.2,0l0.1,0l0.1,0l0.2-0.1l0.1,0l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.3-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.1-0.1l0.1,0l0.1,0l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0l0.1-0.1L981,872.6c5.7-3.1,8.9-9.1,8.9-15.2h0.1v-299C990,551.1,985.5,544.8,979.1,542.3z M755.3,467.5l48.6,24.6l-187.2,97.6l-55.1-30.2L755.3,467.5z M731,951.4L540.9,847.1V587.5l58,31.8v57.4l43,20.3v-54.2l89.2,48.9V951.4L731,951.4z M748.2,661.8l-86-47.1l189-98.6l84.5,42.9L748.2,661.8z" />
            <path d="M736,113.5L520.1,4c-4.9-2.5-10.4-2.3-15-0.2v0L274.4,113.5c-6.4,2.5-11,8.8-11,16.2v299h0.1c0,6.1,3.2,12.1,8.9,15.2l223.3,122.4l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0.1l0.2,0.1l0.1,0l0.1,0l0.1,0.1l0.3,0.1l0.1,0.1l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0l0.2,0.1l0.1,0l0.1,0l0.2,0l0.1,0.1l0,0l0.1,0l0.2,0l0.1,0v0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0.1l0.2,0h0.1l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0h0.1l0.1,0h0.2l0.1,0h0l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0.1h0.1h0.3h0.4h0.1h0.1l0.2,0l0.2,0h0.1h0.1l0.2,0l0.2,0h0l0.1,0h0.2l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.1,0l0.1,0l0.2,0l0.2,0l0.2,0l0.2,0l0.1,0h0.1l0.2,0l0.2-0.1l0,0l0.1,0l0.2,0l0.1,0l0,0l0.1,0l0.2,0l0.1,0v0l0.2-0.1l0.2,0l0.1,0l0.1,0l0.2-0.1l0.1,0l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.1,0l0.1,0l0.1-0.1l0.2-0.1l0.2-0.1l0.1-0.1l0.3-0.1l0.1-0.1l0.1,0l0.1,0l0.1-0.1l0.1-0.1l0.1,0l0.1,0l0.2-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0l0.1-0.1L738,443.8c5.7-3.1,8.9-9.1,8.9-15.2h0.1v-299C747,122.3,742.4,116,736,113.5z M524.5,44.7l48.6,24.6l-187.2,97.6l-55.1-30.2L524.5,44.7z M488.3,528.6L298.1,424.3V164.7l58,31.8V254l43,20.3v-54.2l89.2,48.9L488.3,528.6L488.3,528.6z M505.2,233.1l-86-47.1l189-98.6l84.5,42.9L505.2,233.1z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
