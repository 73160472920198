import React from "react";
import Particles from "react-tsparticles";
import lostSVG from "../assets/svg/raw/404.svg"
import { ThemeContext } from "../components/DarkMode/ThemeProvider"

export default function Error404() {
  const { theme } = React.useContext(ThemeContext);
  return (
    <>

      <div className="w-full min-h-screen">
        <Particles
          id="tsparticles"
          className="absolute w-full h-full"
          options={{
            detectRetina: false,
            fpsLimit: 30,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push"
                },
                onDiv: {
                  elementId: "repulse-div",
                  enable: false,
                  mode: "repulse"
                },
                onHover: {
                  enable: true,
                  mode: "bubble",
                  parallax: {
                    enable: true,
                    force: 60,
                    smooth: 10
                  }
                },
                resize: true
              },
              modes: {
                bubble: {
                  distance: 60,
                  duration: 2,
                  opacity: 8,
                  size: 6,
                  speed: 3
                },
                connect: {
                  distance: 80,
                  lineLinked: {
                    opacity: 0.1
                  },
                  radius: 60
                },
                grab: {
                  distance: 400,
                  lineLinked: {
                    opacity: 1
                  }
                },
                push: {
                  quantity: 4
                },
                remove: {
                  quantity: 2
                },
                repulse: {
                  distance: 200,
                  duration: 0.4
                },
                slow: {
                  active: false,
                  radius: 0,
                  factor: 1
                }
              }
            },
            particles: {
              color: {
                value: ["#351CAB", "#621A61"]
              },
              lineLinked: {
                blink: false,
                color: "random",
                consent: true,
                distance: 20,
                enable: true,
                opacity: 0.8,
                width: 1
              },
              move: {
                attract: {
                  enable: true,
                  rotate: {
                    x: 600,
                    y: 1200
                  }
                },
                bounce: false,
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: true,
                speed: 1,
                straight: false
              },
              number: {
                density: {
                  enable: false,
                  area: 2000
                },
                limit: 0,
                value: 400
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.3,
                  speed: 2,
                  sync: false
                },
                random: false,
                value: 0.8
              },
              size: {
                animation: {
                  enable: true,
                  minimumValue: 0.1,
                  speed: 40,
                  sync: false
                },
                random: true,
                value: 1
              }
            },
            polygon: {
              draw: {
                enable: false,
                lineColor: "rgba(219,52,205,0.9)",
                lineWidth: 1
              },
              enable: true,
              move: {
                radius: 5
              },
              position: {
                x: 50,
                y: 40
              },
              inlineArrangement: "equidistant",
              scale: 2,
              type: "inline",
              url: lostSVG
            },
            background: {
              color: `${theme === "dark" ? "#000000" : "#ffffff"}`,
              repeat: "no-repeat",
              size: "cover"
            }
          }}
        />
        <div className="absolute w-full pointer-events-none">
          <div
            className={`${theme === "dark" ? "text-white" : "text-black"
              } px-8 md:px-0 mt-32 md:mt-64 mx-auto flex flex-col pointer-events-none`}
          >
            <div className="self-center">
              <br />
              <p className="font-dancing text-2xl md:text-6xl">
                Sorry, This Page Doesn't Exist
              </p>
            </div>



          </div>
        </div>
      </div>


    </>
  );
}

