import React from "react";

import nodeLogo from "../pictures/nodelogo.png";
import prestaLogo from "../pictures/prestalogo.png";
import linuxLogo from "../pictures/linuxLogo.png";
import joomlaLogo from "../pictures/joomlaLogo.png";
import quickbooksLogo from "../pictures/quickbooksLogo.png";
import strapiLogo from "../pictures/strapiLogo.png";
import { FaHtml5, FaCss3Alt, FaReact, FaGithub, FaMagento, FaOpencart } from "react-icons/fa";
import { SiAdobe } from "react-icons/si";
import { DiDrupal } from "react-icons/di";
import { DiJavascript } from "react-icons/di";
import { SiTailwindcss } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import { BsFillBootstrapFill } from "react-icons/bs";

export const SkillList = [
  {
    name: "React",
    logo: <FaReact className="w-16 h-16" style={{ color: "#00b7ff" }} />,
  },
  {
    name: "JavaScript",
    logo: <DiJavascript className="w-16 h-16" style={{ color: "#ddb440" }} />,
  },
  {
    name: "Magento",
    logo: <FaMagento className="w-16 h-16" style={{ color: "#f2631c" }} />,
  },
  {
    name: "PrestaShop",
    logo: <img className="w-14 h-16" src={prestaLogo} alt="Presta Shop" />,
  },
  {
    name: "OpenCart",
    logo: <FaOpencart className="w-16 h-16" style={{ color: "#0080C3" }} />,
  },
  {
    name: "MySQL",
    logo: <GrMysql className="w-16 h-16" style={{ color: "#00758F" }} />,
  },
  {
    name: "Drupal",
    logo: <DiDrupal className="w-16 h-16" style={{ color: "#0e5291" }} />,
  },
  {
    name: "Joomla",
    logo: <img className="w-16 h-16" src={joomlaLogo} alt="Joomla" />,
  },
  {
    name: "Strapi",
    logo: <img className="w-16 h-16" src={strapiLogo} alt="Strapi" />,
  },
  {
    name: "Bootstrap",
    logo: (
      <BsFillBootstrapFill className="w-16 h-16" style={{ color: "#563d7c" }} />
    ),
  },
  {
    name: "NodeJS",
    logo: <img className="w-14 h-16" src={nodeLogo} alt="Node JS" />,
  },
  {
    name: "GitHub",
    logo: <FaGithub className="w-16 h-16" style={{ color: "#ffffff" }} />,
  },
  {
    name: "TailwindCSS",
    logo: <SiTailwindcss className="w-16 h-16" style={{ color: "#38b2ac" }} />,
  },
  {
    name: "Adobe",
    logo: <SiAdobe className="w-16 h-16" style={{ color: "#fa0f00" }} />,
  },
  {
    name: "Linux",
    logo: <img className="w-14 h-16" src={linuxLogo} alt="Linux" />,
  },
  {
    name: "QuickBooks",
    logo: <img className="w-14 h-16" src={quickbooksLogo} alt="Quickbooks" />,
  },
  {
    name: "CSS 3",
    logo: <FaCss3Alt className="w-16 h-16" style={{ color: "#258ec8" }} />,
  },
  {
    name: "HTML 5",
    logo: <FaHtml5 className="w-16 h-16" style={{ color: "#dc4d25" }} />,
  },
];
