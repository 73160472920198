import React from "react";

export function ProfilePicture() {
  return (
    <img
      className="h-32 w-117"
      src="/ProfilePictureOld.png"
      alt="profile"
    />
  );
}
