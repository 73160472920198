import React from "react";
// import PortfolioPage from "../../components/Projects/CustomProjects/PortfolioPage";
import DefaultProject from "../../components/Projects/DefaultProject";
import { Text } from "../../components/Multilanguage/Text";

//Icons
import { SiTailwindcss, SiRedis, SiElasticsearch, SiRabbitmq } from "react-icons/si";
import { FaMagento } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { GrGatsbyjs } from "react-icons/gr";

//Pictures
import vanscoFront from "../pictures/VanscoSupply/vanscoFront.jpg";
import vanscoBack from "../pictures/VanscoSupply/vanscoBack.png";
import hhFront from "../pictures/HHSupply/hhFront.png";
import hhBack from "../pictures/HHSupply/hhBack.png";
import trvFront from "../pictures/TrvDesign/trvFront.png";
import trvBack from "../pictures/TrvDesign/trvBack.png";
import prestaLogo from "../pictures/prestalogo.png";
import materialuiLogo from "../pictures/materialui.svg";



const projectList = [
  <DefaultProject
    name={"Vansco Supply"}
    imageFront={vanscoFront}
    imageBack={vanscoBack}
    githubLink={"/#"}
    liveVersionLink={"https://www.vanscosupply.net/"}
    hasLoginCredentials={false}
    projectShortDescription={<Text tid="vanscoSubheading" />}
    aboutProjectText={
      <div >
        <Text tid="aboutVansco1" />
        <br /> <br />
        <Text tid="aboutVansco2" />
        <br /> <br />
        <Text tid="aboutVansco3" />
      </div>
    }
    features={[
      <Text tid="vanscoFeature1" />,
      <Text tid="vanscoFeature2" />,
      <Text tid="vanscoFeature3" />,
      <Text tid="vanscoFeature4" />,
      <Text tid="vanscoFeature5" />,
      <Text tid="vanscoFeature6" />,
    ]}
    techStack={[
      {
        name: "Magento 2",
        logo: <FaMagento className="w-12 h-12" style={{ color: "#f2631c" }} />,
      },
      {
        name: "Redis",
        logo: <SiRedis className="w-12 h-12" style={{ color: "#A52521" }} />,
      },
      {
        name: "ElasticSearch",
        logo: <SiElasticsearch className="w-12 h-12" style={{ color: "#159388" }} />,
      },
      {
        name: "RabbitMQ",
        logo: <SiRabbitmq className="w-12 h-12" style={{ color: "#F26100" }} />,
      },
    ]}
  />,

  <DefaultProject
    name={"H & H Show Supplies"}
    imageFront={hhFront}
    imageBack={hhBack}
    githubLink={"/#"}
    liveVersionLink={"https://handhshowsupplies.com/"}
    projectShortDescription={<Text tid="hhSubheading" />}
    aboutProjectText={
      <div>
        <Text tid="aboutHh1" />
        <br /> <br />
        <Text tid="aboutHh2" />
      </div>
    }
    features={[
      <Text tid="hhFeature1" />,
      <Text tid="hhFeature2" />,
      <Text tid="hhFeature3" />,
      <Text tid="hhFeature4" />,
      <Text tid="hhFeature5" />,
      <Text tid="hhFeature6" />,
    ]}
    techStack={[
      {
        name: "PrestaShop",
        logo: <img className="w-16 h-16" src={prestaLogo} alt="Presta Shop" />,
      },
      {
        name: "Gatsby",
        logo: (
          <GrGatsbyjs className="w-16 h-16" style={{ color: "#571D90" }} />
        ),
      },
      {
        name: "React",
        logo: <FaReact className="w-16 h-16" style={{ color: "#00b7ff" }} />,
      },
    ]}
  />,
  <DefaultProject
    name={"TRV Design"}
    imageFront={trvFront}
    imageBack={trvBack}
    githubLink={"/#"}
    liveVersionLink={"/"}
    projectShortDescription={<Text tid="trvSubheading" />}
    aboutProjectText={
      <div>
        <Text tid="aboutTrv1" />
        <br /> <br />
        <Text tid="aboutTrv2" />
        <br /> <br />
      </div>
    }
    features={[
      <Text tid="trvFeature1" />,
      <Text tid="trvFeature2" />,
      <Text tid="trvFeature3" />,
      <Text tid="trvFeature4" />,
      <Text tid="trvFeature5" />,
      <Text tid="trvFeature6" />,
    ]}
    techStack={[
      {
        name: "React",
        logo: <FaReact className="w-16 h-16" style={{ color: "#00b7ff" }} />,
      },
      {
        name: "Tailwind CSS",
        logo: (
          <SiTailwindcss className="w-16 h-16" style={{ color: "#38b2ac" }} />
        ),
      },
      {
        name: "Material UI",
        logo: <img className="w-16 h-16" src={materialuiLogo} alt="Material UI" />,
      },
    ]}
  />,

];

export default projectList;
