import React from "react";
import { GradientPinkBlue as IconGradient } from "@vx/gradient";

export function HostingSVG() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 512 512"
      fill="url(#iconGradient)"
    >
      <IconGradient id="iconGradient" />
      <g>
        <g>
          <g>
            <path d="M485.744,0.269H26.256C11.755,0.269,0,12.024,0,26.526v137.958c0,14.501,11.755,26.256,26.256,26.256h459.487
				c14.501,0,26.256-11.755,26.256-26.256V26.526C512,12.024,500.245,0.269,485.744,0.269z M287.727,108.633H93.893
				c-7.251,0-13.128-5.878-13.128-13.128s5.878-13.128,13.128-13.128h193.834c7.251,0,13.128,5.877,13.128,13.128
				S294.978,108.633,287.727,108.633z M388.966,134.329c-21.408,0-38.824-17.416-38.824-38.824
				c0-21.407,17.416-38.824,38.824-38.824c21.407,0,38.824,17.417,38.824,38.824S410.373,134.329,388.966,134.329z"/>
            <path d="M492.308,472.346H275.688v-32.058c0-3.625-2.939-6.564-6.564-6.564h-26.256c-3.625,0-6.564,2.939-6.564,6.564v32.058
				H19.692C8.817,472.346,0,481.163,0,492.039c0,10.875,8.817,19.692,19.692,19.692h472.615c10.875,0,19.692-8.817,19.692-19.692
				S503.183,472.346,492.308,472.346z"/>
            <path d="M485.744,216.996H26.256C11.755,216.996,0,228.751,0,243.252V381.21c0,14.501,11.755,26.256,26.256,26.256h459.487
				c14.501,0,26.256-11.755,26.256-26.256V243.252C512,228.752,500.245,216.996,485.744,216.996z M287.727,325.36H93.893
				c-7.251,0-13.128-5.878-13.128-13.128c0-7.251,5.878-13.128,13.128-13.128h193.834c7.251,0,13.128,5.878,13.128,13.128
				S294.978,325.36,287.727,325.36z M388.966,351.056c-21.408,0-38.824-17.416-38.824-38.824c0-21.407,17.416-38.824,38.824-38.824
				c21.407,0,38.824,17.416,38.824,38.824S410.373,351.056,388.966,351.056z"/>
          </g>
        </g>
      </g>
    </svg>
  );
}
